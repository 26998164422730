
import { eoRendererMixin, metaDataMixin, dateFormatMixin } from '~/mixins'

export default {
  mixins: [
    eoRendererMixin,
    metaDataMixin,
    dateFormatMixin,
  ],
  async asyncData({ $api, $config, $link, $log, store, route, error, params: { slug } }) {
    const {
      data: { items: articles = [] } = {},
    } = await $api.articles.getOneBySlug(slug, {
      domain: $config.preprEnvironmentDomain,
    })

    if (!articles.length || !articles[0]) {
      error({
        statusCode: 404,
        message: '(404) Artikel niet gevonden',
        context: `Couldn't find publication: ${slug}.`,
        severity: 'warning',
      })
      return false
    }

    const article = articles[0]

    // Get amount of likes for this article
    let likes = 0
    try {
      const {
        data: {
          item: {
            likes: likesCount = 0,
          } = {},
        } = {},
      } = await $api.articles.getAllNested(
        "likes",
        article.id,
      )
      likes = likesCount
    } catch (e) {
      $log.info('No likes found for:', { article: article.id })
    }

    // Set breadcrumbs
    store.dispatch('breadcrumbs/set', {
      path: route.path,
      width: 6,
      eoRoot: !article.program,
      items: [
        article.program
          ? {
            to: $link.publication(article.program),
            label: article.program.title,
          }
          : {
            to: '/',
            label: 'BEAM',
          },
        {
          label: article.title,
        },
      ],
    })

    return {
      ...article,
      likes,
    }
  },
  data() {
    return {
      id: null,
      changedOn: null,
      publishOn: null,
      slug: null,
      readTime: null,
      modelId: null,
      modelName: null,
      environmentId: null,
      title: null,
      subtitle: null,
      featuredVideo: null,
      hero: null,
      intro: null,
      likes: null,
      content: [],
      seo: null,
      social: null,
      tags: null,
      stories: null,
      topic: null,
      program: null,
      podcast: null,
      author: null,
      type: null,
      location: null,
      relatedArticles: [],
      callToAction: null,
      amountRelatedArticlesAnywhere: 0,
      amountRelatedArticlesCommunity: 0,
    }
  },
  async fetch() {
    // Get specific CTA
    const {
      data: {
        items: callToActions = [],
      } = {},
    } = await this.$api.calltoactions.getOneBySlug('volg-beam-op-tiktok')
    this.callToAction = callToActions[0]
  },
  computed: {
    isAuthorExternal() {
      return this.author?.role?.some((role) => {
        return role.slug === 'gastauteur'
      })
    },
    labels() {
      const labels = []

      if (this.topic) {
        labels.push({
          slug: this.topic.slug,
          name: this.topic.name,
          to: `${this.$config.baseUrlEoHome}/thema/${this.topic.slug}`,
          icon: 'spirit',
          textColor: 'purple-700',
        })
      }

      if (this.stories && this.stories.length) {
        this.stories.forEach((story) => {
          labels.push({
            slug: story.slug,
            name: story.name,
            to: `/rubriek/${story.slug}`,
          })
        })
      }

      if (this.tags && this.tags.length) {
        this.tags.forEach((tag) => {
          labels.push({
            slug: tag.slug,
            name: tag.name,
            to: `/alles-over/${tag.slug}`,
          })
        })
      }

      return labels
    },
  },
  mounted() {
    // Get related articles after 2 seconds
    setTimeout(function () {
      this.getRelatedArticles()
    }.bind(this), 2000)
  },
  methods: {
    async getRelatedArticles(ratio) {
      if (this.relatedArticles.length) return
      if (ratio <= 0) return
      if (!this.id) return

      // From all of EO
      const {
        data: {
          items: rArticles = [],
        } = {},
      } = await this.$api.recommendations.getSimilar(
        {
          id: this.id,
          resource: 'articles',
        },
      )
      if (!rArticles || !rArticles.length) {
        this.$log.info('No related articles found for:', { id: this.id })
      }

      this.relatedArticles = rArticles

      // reset values
      this.amountRelatedArticlesCommunity = 0
      this.amountRelatedArticlesAnywhere = 0

      // Note community of first 6 items
      this.relatedArticles.slice(0, 6).forEach(article => {
        if (this.$library.prepr.environments[article.environmentId]?.slug === this.$config.preprEnvironmentDomain) {
          this.amountRelatedArticlesCommunity += 1
        } else {
          this.amountRelatedArticlesAnywhere += 1
        }
      })
    },
  },
  jsonld() {
    return {
      '@context': 'http://schema.org',
      '@type': 'Article',
      datePublished: this.publishOn,
      dateModified: this.changeOn,
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': this.$config.baseUrlCanonical + this.$route.path,
      },
      headline: this.seo && this.seo.title,
      description: this.seo && this.seo.description,
      image: this.hero,
      author: {
        '@type': 'Person',
        name: this.author && this.author.name,
        // @todo: add image of author (if it is available in first call)
        // @todo: add url of author detailpage as 'mainEntityOfPage'
        // @todo: add url of author detailpage as 'sameAs'
      },
      publisher: {
        '@type': 'Organization',
        name: 'EO',
        logo: {
          '@type': 'ImageObject',
          url: this.$config.baseUrlCanonical + require('~/assets/logo/evangelische-omroep.webp'),
        },
      },
    }
  },
}
